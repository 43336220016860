import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function NiceSelect({ name, options, onChange, selected, useScroll, testId }) {
  const [current, setCurrent] = useState({});
  const [listOpened, setListOpened] = useState(false);

  useEffect(() => {
    const selectedItem = options.find((option) => option.value === selected);
    setCurrent(selectedItem);
  }, [setCurrent, options, selected]);

  return (
    <React.Fragment>
      <div
        className={`nice-select form-control required ${
          listOpened ? "open" : ""
        }`}
        tabIndex="0"
        onClick={() => {
          setListOpened(!listOpened);
        }}
        onBlur={() => {
          setListOpened(false);
        }}
        data-testid={testId}
      >
        <span className="current">{current?.label}</span>
        <ul className={`list ${useScroll ? "use-scroll" : ""}`}>
          {options.map((option) => {
            const selectedClassName = "selected focus";
            return (
              <li
                key={option.value}
                data-name={name}
                data-value={option.value}
                onClick={onChange}
                className={`option ${
                  option.value === selected ? selectedClassName : ""
                }`}
              >
                {option.label}
              </li>
            );
          })}
        </ul>
      </div>
    </React.Fragment>
  );
}

NiceSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.any,
  useScroll: PropTypes.bool,
  testId: PropTypes.any,
};

export default NiceSelect;
