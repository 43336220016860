import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { preventChangeFocus } from "../../../../../shared/helpers";
import { goalOptions } from "../../../../constants/options";
import {
  LEAD_SET_FIELDS,
  LEAD_CHANGE_STEP,
} from "../../../../reducers/actions";

const GoalsStep = () => {
  const goals = useSelector((state) => state.leadForm.fields.goals);
  const currentStep = useSelector((state) => state.leadForm.currentStep);
  const isLoading = useSelector((state) => state.leadForm.stepLoading);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const checkedGoals = goals;
    if (event.target.checked) {
      checkedGoals.push(event.target.value);
    } else {
      checkedGoals.splice(checkedGoals.indexOf(event.target.value), 1);
    }

    dispatch({
      type: LEAD_SET_FIELDS,
      payload: {
        goals: checkedGoals,
      },
    });
  };

  return (
    <section
      className={`sqz-section ${
        currentStep === 3 && !isLoading ? "" : "sqz-hide"
      }`}
      id="step-3"
      data-testid="form-step-3"
    >
      <img
        src="/assets/images/typing-bubble.svg"
        alt="typing-bubble"
        className="sqz-typing_bubble"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10 text-center">
            <div className="sqz-question_header">
              <h2>What features are important to you?</h2>
              <p>You can select more than one</p>
            </div>
            <div className="sqz-form_row">
              <ul className="sqz-goals row">
                {goalOptions.map((item, index) => {
                  return (
                    <li key={index} className="col-6 col-sm-6 col-xl-6">
                      <div className="sqz-checkbox_wrap">
                        <input
                          type="checkbox"
                          name="goals"
                          default={goals.indexOf(item.value) !== -1}
                          onChange={handleChange}
                          id={`sqz-checkbox_3_${index + 1}`}
                          value={item.value}
                          className="required"
                        />
                        <label
                          className={`sqz-check_label sqz-${item.value}`}
                          htmlFor={`sqz-checkbox_3_${index + 1}`}
                          data-testid={`goals-${index}`}
                        >
                          {item.label}
                        </label>
                        <span></span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <button
              type="button"
              className="sqz-btn sqz-next"
              onClick={() => {
                dispatch({ type: LEAD_CHANGE_STEP, payload: 4 });
              }}
              data-testid="goto-step4"
              onKeyDown={preventChangeFocus}
            >
              Next
            </button>
            <br />
            <button
              type="button"
              className="sqz-back"
              onClick={() => {
                dispatch({ type: LEAD_CHANGE_STEP, payload: 2 });
              }}
              data-testid="goback-to-step2"
              onKeyDown={preventChangeFocus}
            >
              Prev
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GoalsStep;
