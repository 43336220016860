import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const PartnerHeader = ({ partnerName, logoFileName, partnerWebsiteUrl }) => {
  return (
    <header className="sqz-header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-4">
            {logoFileName && (
              <div className="sqz-logo">
                <a href={partnerWebsiteUrl}>
                  <img
                    src={`assets/images/partner-logo/${logoFileName}`}
                    alt={partnerName}
                  />
                </a>
              </div>
            )}
          </div>
          <div className="col-8 text-right">
            <ul className="sqz-nav">
              <li className="powered-by-quest">
                <a href={process.env.REACT_APP_WEBSITE_URL}>
                  <img
                    src="/assets/images/powered-by-quest.svg"
                    alt="Powered By Quest"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

PartnerHeader.propTypes = {
  partnerName: PropTypes.string,
  logoFileName: PropTypes.string,
  partnerWebsiteUrl: PropTypes.string,
};

export default PartnerHeader;
