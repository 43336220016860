import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import CurrencyInput from "../../../../../shared/components/CurrencyInput";
import {
  MAXIMUM_LOAN_AMOUNT,
  MINIMUM_LOAN_AMOUNT,
} from "../../../../../shared/constants";
import {
  preventChangeFocus,
  handleEnterKey,
  convertToCurrency,
} from "../../../../../shared/helpers";
import {
  LEAD_SET_FIELDS,
  LEAD_CHANGE_STEP,
  LEAD_SET_PREFILL_FIELD,
  LEAD_SET_FORM_ERRORS,
} from "../../../../reducers/actions";

const AmountStep = () => {
  const submitBtn = useRef();
  const amount = useSelector((state) => state.leadForm.fields.amount);
  const purpose = useSelector((state) => state.leadForm.fields.purpose);
  const currentStep = useSelector((state) => state.leadForm.currentStep);
  const isLoading = useSelector((state) => state.leadForm.stepLoading);

  const dispatch = useDispatch();
  const { register, trigger, errors } = useForm();

  const amountFieldName = "amount";
  const minimumAmountCurrency = convertToCurrency(MINIMUM_LOAN_AMOUNT);
  const maximumAmountCurrency = convertToCurrency(MAXIMUM_LOAN_AMOUNT);

  const prefilledFieldName = useSelector(
    (state) => state.leadForm.prefillField.fieldName
  );

  register(amountFieldName, {
    validate: {
      min: () => {
        return (
          parseFloat(amount) >= MINIMUM_LOAN_AMOUNT ||
          `Minimum amount is ${minimumAmountCurrency}`
        );
      },
      max: () => {
        return (
          parseFloat(amount) <= MAXIMUM_LOAN_AMOUNT ||
          `Maximum amount is ${maximumAmountCurrency}`
        );
      },
    },
  });

  const toNextStep = async () => {
    const validation = await trigger(amountFieldName);
    if (validation) {
      dispatch({
        type: LEAD_SET_FORM_ERRORS,
        payload: null,
      });

      dispatch({
        type: LEAD_CHANGE_STEP,
        payload: 2,
      });

      if (purpose !== "" && purpose !== null) {
        dispatch({
          type: LEAD_SET_PREFILL_FIELD,
          payload: {
            fieldName: "purpose",
            fieldValue: purpose,
          },
        });
      }
    } else {
      dispatch({
        type: LEAD_SET_FORM_ERRORS,
        payload: errors,
      });
    }
  };

  useEffect(() => {
    if (prefilledFieldName === "amount") {
      submitBtn.current.click();
    }
  }, [prefilledFieldName]);

  return (
    <section
      className={`sqz-section ${
        currentStep === 1 && !isLoading ? "" : "sqz-hide"
      }`}
      id="step-1"
      data-testid="form-step-1"
    >
      <img
        src="/assets/images/typing-bubble.svg"
        alt="typing-bubble"
        className="sqz-typing_bubble"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10 text-center">
            <div className="sqz-question_header">
              <h2>How much do you need?</h2>
            </div>
            <div className="sqz-form_row">
              <div className="sqz-currency">
                <CurrencyInput
                  name="amount"
                  value={amount}
                  className="form-control"
                  testId="amount"
                  onValueChange={({ value }) =>
                    dispatch({
                      type: LEAD_SET_FIELDS,
                      payload: { amount: value },
                    })
                  }
                  onKeyDown={(event) => {
                    handleEnterKey(event, () => {
                      toNextStep();
                    });
                  }}
                />
              </div>
              {errors.amount && (
                <label className="error">{errors.amount.message}</label>
              )}
            </div>
            <button
              ref={submitBtn}
              type="button"
              className="sqz-btn sqz-next"
              onClick={toNextStep}
              data-testid="goto-step2"
              onKeyDown={preventChangeFocus}
            >
              LET&apos;S DO THIS
            </button>
            <p className="sqz-dark">
              Takes 60 seconds - Won&apos;t affect your credit score
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AmountStep;
