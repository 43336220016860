import TagManager from "react-gtm-module";

export default () => {
  const GTM_ID = process.env.REACT_APP_GTM_ID;

  if (GTM_ID) {
    TagManager.initialize({
      gtmId: GTM_ID,
    });
  }
};
