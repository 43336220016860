export const PMT_TYPE_END_OF_PERIOD = 0;
export const PMT_TYPE_BEGINNING_OF_PERIOD = 1;

export const calculateFutureValue = (amount, percentage) => {
  return (amount * percentage) / 100;
};

export const pmt = (rate, period, presentValue, futureValue, type) => {
  if (rate === PMT_TYPE_END_OF_PERIOD) {
    const total = presentValue - futureValue;
    return Math.round((total / period) * 100) / 100;
  }

  const monthlyInterestRate = rate / 12;
  const monthlyInterest = monthlyInterestRate / 100;

  const compoundMultiplier = Math.pow(1 + monthlyInterest, period);
  let grandTotal =
    (monthlyInterest * (presentValue * compoundMultiplier - futureValue)) /
    (compoundMultiplier - 1);

  if (type === PMT_TYPE_BEGINNING_OF_PERIOD) {
    grandTotal /= 1 + monthlyInterest;
  }

  return Math.round(grandTotal * 100) / 100;
};

const calculateRepayment = (
  loanAmount,
  loanTerm,
  interestRate,
  balloonRepayment,
  repaymentFrequency
) => {
  const futureValue = calculateFutureValue(loanAmount, balloonRepayment);
  let monthlyRepayment = pmt(
    interestRate,
    loanTerm,
    loanAmount,
    futureValue,
    PMT_TYPE_BEGINNING_OF_PERIOD
  );

  if (repaymentFrequency === "fortnightly") {
    return (monthlyRepayment * 12) / 26;
  } else if (repaymentFrequency === "weekly") {
    return (monthlyRepayment * 12) / 52;
  }

  return monthlyRepayment;
};

export default calculateRepayment;
