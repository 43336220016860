import React from "react";
import { useSelector } from "react-redux";
import DefaultPartner from "./pages/default-partner";
import { partners } from "./pages/default-partner/constants";
import DefaultQuest from "./pages/default-quest";

const LeadForm = () => {
  const referrer = useSelector((state) => state.leadForm.fields.ref);
  const partner = partners.filter((partner) => partner.slug === referrer)[0];

  if (partner) {
    return <DefaultPartner partner={partner} />;
  }

  return <DefaultQuest />;
};

export default LeadForm;
