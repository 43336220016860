import React, { useState } from "react";
import "./components/css/desktop.css";
import InputField from "./components/input";
import QuoteSummary from "./components/QouteSummary";

function Desktop() {
  const [formData, setFormData] = useState({
    purchasePrice: "",
    cashDeposit: "",
    tradeIn: "",
    payoutAmount: "",
    financierRate: "",
    brokerOriginationFees: "",
    loanApplicationFee: "",
    term: "",
    paymentCycle: "Monthly",
    paymentTime: "Advance",
    financeBrokerageFee: false,
    financeApplicationFee: false,
    commisionToBroker: 0,
  });

  // Combined state for input and calculated values
  const [balloonState, setBalloonState] = useState({
    amount: "",
    percentage: "",
  });

  const [brokerageState, setBrokerageState] = useState({
    amount: "",
    percentage: "",
  });

  const calculateBaseLoanAmount = (formData) => {
    const purchasePrice =
      parseFloat(formData.purchasePrice.replace(/[^0-9.-]+/g, "")) || 0;
    const payoutAmount =
      parseFloat(formData.payoutAmount.replace(/[^0-9.-]+/g, "")) || 0;
    const tradeIn = parseFloat(formData.tradeIn.replace(/[^0-9.-]+/g, "")) || 0;
    const cashDeposit =
      parseFloat(formData.cashDeposit.replace(/[^0-9.-]+/g, "")) || 0;
    return Math.max(0, purchasePrice + payoutAmount - tradeIn - cashDeposit);
  };

  const calculateNetAmountFinanced = (formData, baseLoanAmount) => {
    let netAmount = baseLoanAmount;
    const loanApplicationFee =
      parseFloat(formData.loanApplicationFee.replace(/[^0-9.-]+/g, "")) || 0;
    const brokerOriginationFees =
      parseFloat(formData.brokerOriginationFees.replace(/[^0-9.-]+/g, "")) || 0;

    if (formData.financeApplicationFee) {
      netAmount += loanApplicationFee * 1.1;
    }
    if (formData.financeBrokerageFee) {
      netAmount += brokerOriginationFees * 1.1;
    }
    return netAmount;
  };

  const handleInputChange = (eventOrName, valueOrChecked) => {
    let name, value, type, checked;
    if (typeof eventOrName === "string") {
      name = eventOrName;
      value = valueOrChecked;
      type = typeof valueOrChecked === "boolean" ? "checkbox" : "text";
      checked = valueOrChecked;
    } else if (eventOrName && eventOrName.target) {
      ({ name, value, type, checked } = eventOrName.target);
    } else {
      return;
    }

    if (type === "checkbox") {
      setFormData((prev) => ({ ...prev, [name]: checked }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }

    const baseLoanAmount = calculateBaseLoanAmount(formData);
    const netAmountFinanced = calculateNetAmountFinanced(
      formData,
      baseLoanAmount
    );

    // Handle balloon and brokerage fields
    if (name === "balloonAmount") {
      const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
      setBalloonState({
        amount: value,
        percentage:
          baseLoanAmount > 0
            ? ((numericValue / baseLoanAmount) * 100).toFixed(2)
            : "0",
      });
    } else if (name === "balloonPercentage") {
      const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
      setBalloonState({
        amount: ((numericValue / 100) * baseLoanAmount).toFixed(2),
        percentage: value,
      });
    } else if (name === "brokerageAmount") {
      const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
      setBrokerageState({
        amount: value,
        percentage:
          netAmountFinanced > 0
            ? ((numericValue / netAmountFinanced) * 100).toFixed(2)
            : "0",
      });
    } else if (name === "brokeragePercentage") {
      const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
      setBrokerageState({
        amount: ((numericValue / 100) * netAmountFinanced).toFixed(2),
        percentage: value,
      });
    }

    // Update balloon and brokerage calculations when base values change
    if (
      [
        "purchasePrice",
        "payoutAmount",
        "tradeIn",
        "cashDeposit",
        "loanApplicationFee",
        "brokerOriginationFees",
        "financeApplicationFee",
        "financeBrokerageFee",
      ].includes(name)
    ) {
      // Recalculate balloon based on current percentage
      const balloonPercentValue =
        parseFloat(balloonState.percentage.replace(/[^0-9.-]+/g, "")) || 0;
      setBalloonState((prev) => ({
        ...prev,
        amount: ((balloonPercentValue / 100) * baseLoanAmount).toFixed(2),
      }));

      // Recalculate brokerage based on current percentage
      const brokeragePercentValue =
        parseFloat(brokerageState.percentage.replace(/[^0-9.-]+/g, "")) || 0;
      setBrokerageState((prev) => ({
        ...prev,
        amount: ((brokeragePercentValue / 100) * netAmountFinanced).toFixed(2),
      }));
    }
  };
  return (
    <div className="container">
      <div className="quote-form">
        <h2>Quote</h2>
        <form>
          <div className="input-row">
            <div className="input-column">
              <InputField
                label="Purchase price *"
                placeholder="$"
                type="text"
                format="money"
                onChange={(e) => handleInputChange(e)}
                value={formData.purchasePrice}
                name="purchasePrice"
              />
            </div>
            <div className="input-column">
              <InputField
                label="Cash deposit"
                placeholder="$"
                type="text"
                format="money"
                onChange={(e) => handleInputChange(e)}
                value={formData.cashDeposit}
                name="cashDeposit"
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-column">
              <InputField
                label="Trade in"
                placeholder="$"
                type="text"
                format="money"
                onChange={(e) => handleInputChange(e)}
                value={formData.tradeIn}
                name="tradeIn"
              />
            </div>
            <div className="input-column">
              <InputField
                label="Payout amount"
                placeholder="$"
                type="text"
                format="money"
                onChange={(e) => handleInputChange(e)}
                value={formData.payoutAmount}
                name="payoutAmount"
              />
            </div>
          </div>
          <div className="full-width-frame">
            <label className="text-wrapper">Balloon</label>
            <div className="input-split">
              <div className="input-wrapper">
                <InputField
                  label=""
                  placeholder="%"
                  type="text"
                  format="percentage"
                  onChange={(e) => handleInputChange(e)}
                  value={balloonState.percentage}
                  name="balloonPercentage"
                />
              </div>
              <div className="input-wrapper">
                <InputField
                  label=""
                  placeholder="$"
                  type="text"
                  format="money"
                  onChange={(e) => handleInputChange(e)}
                  value={balloonState.amount}
                  name="balloonAmount"
                />
              </div>
            </div>
          </div>
          <div className="full-width-frame">
            <label className="text-wrapper">Brokerage (ex GST)</label>
            <div className="input-split">
              <div className="input-wrapper">
                <InputField
                  label=""
                  placeholder="%"
                  type="text"
                  format="percentage"
                  onChange={(e) => handleInputChange(e)}
                  value={brokerageState.percentage}
                  name="brokeragePercentage"
                />
              </div>
              <div className="input-wrapper">
                <InputField
                  label=""
                  placeholder="$"
                  type="text"
                  format="money"
                  onChange={(e) => handleInputChange(e)}
                  value={brokerageState.amount}
                  name="brokerageAmount"
                />
              </div>
            </div>
          </div>
          <InputField
            label="Financier rate"
            placeholder="%"
            type="text"
            format="percentage"
            onChange={handleInputChange}
            value={formData.financierRate}
            name="financierRate"
          />
          <div className="spacer"></div> {/* Add spacing after Term */}
          <InputField
            label="Term (years)"
            type="radio"
            name="term"
            options={[
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
              { value: "4", label: "4" },
              { value: "5", label: "5" },
              { value: "6", label: "6" },
              { value: "7", label: "7" },
            ]}
            onChange={handleInputChange}
            value={formData.term}
          />
          <div className="spacer"></div> {/* Add spacing after Term */}
          <InputField
            label="Payment Cycle"
            type="toggle"
            toggle={{
              options: ["Monthly", "Weekly"],
              initialOption: formData.paymentCycle || "Monthly",
            }}
            onChange={handleInputChange}
            name="paymentCycle"
          />
          <div className="spacer"></div> {/* Add spacing */}
          <InputField
            label="Payment Time"
            type="toggle"
            toggle={{
              options: ["Advance", "Arrears"],
              initialOption: formData.paymentTime || "Advance",
            }}
            onChange={handleInputChange}
            name="paymentTime"
          />
          <div className="spacer"></div> {/* Add spacing */}
          <div className="input-row">
            <div className="input-column">
              <InputField
                label="Origination Fee (ex GST)"
                placeholder="$"
                type="text"
                format="money"
                onChange={handleInputChange}
                value={formData.brokerOriginationFees}
                name="brokerOriginationFees"
              />
            </div>
            <div className="input-column">
              <InputField
                label="Loan Application Fee (ex GST)"
                placeholder="$"
                type="text"
                format="money"
                onChange={handleInputChange}
                value={formData.loanApplicationFee}
                name="loanApplicationFee"
              />
            </div>
          </div>
          <div className="spacer"></div> {/* Add spacing */}
          <div className="checkbox-row">
            <div className="checkbox-column">
              <InputField
                label="Finance Origination Fee"
                type="checkbox"
                checked={formData.financeBrokerageFee}
                onChange={handleInputChange}
                name="financeBrokerageFee"
              />
            </div>
            <div className="checkbox-column">
              <InputField
                label="Finance Application Fee"
                type="checkbox"
                checked={formData.financeApplicationFee}
                onChange={handleInputChange}
                name="financeApplicationFee"
              />
            </div>
          </div>
        </form>
      </div>

      <QuoteSummary
        formData={{
          ...formData,
          balloonAmount: balloonState.amount,
          balloonPercentage: balloonState.percentage,
          brokerageAmount: brokerageState.amount,
          brokeragePercentage: brokerageState.percentage,
        }}
      />
    </div>
  );
}

export default Desktop;
