import React from "react";
import PropTypes from "prop-types";
import { colorScheme } from "../../types";

const Form = (props) => {
  return (
    <div id="lead-form" data-testid="lead-form">
      <div id="sqz-progress_bar" className="sqz-progress_bar">
        <div
          className={`sqz-progress_percentage step-${props.currentStep}`}
          style={{
            background: props.colorScheme.progressBar,
          }}
        ></div>
        <ul className="sqz-steps">
          {Array.apply(null, { length: props.stepsCount }).map((e, index) => {
            return (
              <li key={index}>
                <span
                  className={`sqz-steps_dot sqz-dot_${index + 1} ${
                    props.currentStep >= index + 1 ? "sqz-show" : ""
                  }`}
                  style={{
                    background: props.colorScheme.progressBarDots,
                  }}
                ></span>
              </li>
            );
          })}
        </ul>
      </div>
      <div id="sqz-content" className="sqz-content">
        <form id="sqz-form">
          <div className="sqz-form_content" style={props.formStyles}>
            {props.children}
          </div>
        </form>
      </div>
    </div>
  );
};

Form.propTypes = {
  currentStep: PropTypes.number,
  stepsCount: PropTypes.number,
  formStyles: PropTypes.object,
  children: PropTypes.element,
  colorScheme,
};

export default Form;
