import React from "react";
import PropTypes from "prop-types";

function RadioGroup({ name, options, checked, onChange, center }) {
  return (
    <ul
      className={`sqz-select_buttons row ${
        center ? "justify-content-center" : ""
      }`}
    >
      {options.map((option, index) => {
        return (
          <li className="col-12 col-md-4" key={`${name}-${index}`}>
            <input
              type="radio"
              name={name}
              id={`${name}-${index}`}
              value={option.value}
              className="required"
              checked={option.value === checked}
              onChange={onChange}
            />
            <label
              className="sqz-sel_btn"
              htmlFor={`${name}-${index}`}
              data-testid={`${name}-${option.value}`}
            >
              {option.label}
            </label>
          </li>
        );
      })}
    </ul>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  checked: PropTypes.any,
  onChange: PropTypes.func,
  center: PropTypes.bool,
};

export default RadioGroup;
