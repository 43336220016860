export const API_URL = process.env.REACT_APP_API_URL;

export async function call(method, path, payload) {
  try {
    let response = await fetch(API_URL + path, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method,
      body: !payload ? null : JSON.stringify(payload),
    });
    response = await response.json();

    if (response.errorCode) {
      throw response;
    }

    return response.data;
  } catch (error) {
    if (error instanceof Error) throw new Error(error);
    throw error;
  }
}

export async function submit(data) {
  return call("POST", "/lead/business-loan", data);
}

export async function verifyOtp(data) {
  return call("POST", "/lead/verify-otp", data);
}

export async function resendOtp(data) {
  return call("POST", "/lead/resend-otp", data);
}
