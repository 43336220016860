export const purposeOptions = [
  { label: "General Equipment", value: "plant_and_equipment" },
  { label: "Construction Equipment", value: "business_cash_flow" },
  { label: "Buy a Vehicle", value: "buy_a_vehicle" },
  { label: "Truck / Trailer", value: "truck_and_trailer" },
  { label: "Farming Equipment", value: "it_equipment" },
  { label: "Medical Equipment", value: "grow_my_business" },
];

export const goalOptions = [
  { label: "Lowest rate possible", value: "lowest_rate" },
  { label: "Option to payout loan early", value: "early_payout" },
  { label: "Flexible Repayments", value: "major_lender" },
  { label: "Lowest repayment possible", value: "lowest_repayment" },
];

export const periodOptions = [
  { label: "2 years", value: 24 },
  { label: "3 years", value: 36 },
  { label: "4 years", value: 48 },
  { label: "5 years", value: 60 },
];

export const businessAgeMonthOptions = [
  { label: "Less than 12 months", value: 11 },
  { label: "1-2 years", value: 24 },
  { label: "2-5 years", value: 60 },
  { label: "More than 5 years", value: 61 },
];

export const creditHistoryOptions = [
  { label: "Excellent - No Issues", value: "excellent" },
  { label: "Average - Paid Default", value: "average" },
  { label: "Fair - Let's Chat", value: "fair" },
  { label: "Not Sure", value: "not_sure" },
];

export const monthlyTurnoverOptions = [
  { label: "Less than $10,000", value: 9999 },
  { label: "$10,000 - $20,000 ", value: 10000 },
  { label: "$20,000 - $30,000", value: 20000 },
  { label: "$30,000 - $50,000", value: 30000 },
  { label: "$50,000 - $100,000", value: 50000 },
  { label: "$100,000+", value: 100001 },
];

export const neededByOptions = [
  { label: "Ready to go", value: 0 },
  { label: "1 week", value: 7 },
  { label: "30 days", value: 30 },
  { label: "More than 30 days", value: 31 },
];
