export const roundAccurately = (number, decimalPlaces) => {
  if (isNaN(number)) return "0";
  return Number(
    Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces
  );
};

export const preventChangeFocus = (event) => {
  if (event.keyCode === 9) event.preventDefault();
};

export const handleEnterKey = (event, callback) => {
  if (event.keyCode === 13) {
    callback();
  }
};

export const redirectParentWindow = (url) => {
  if (window.top !== window) {
    window.parent.postMessage(
      {
        questLeadFormUrl: url,
      },
      "*"
    );
  } else {
    setWindowLocationHref(url);
  }
};

export const setWindowLocationHref = (url) => {
  window.location.href = url;
};

export const captureQueryParams = (paramString, fieldsToGet) => {
  const queryParams = new URLSearchParams(paramString);
  const fields = {};

  fieldsToGet.forEach((field) => {
    fields[field] = queryParams.get(field) || null;
  });

  return fields;
};

export const convertSnakeToCamel = (string) => {
  if (typeof string === "string") {
    return string.replace(/([-_][a-z])/g, (g) => g[1].toUpperCase());
  }

  return string;
};

export const convertToCurrency = (number, options) => {
  return new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    currencyDisplay: "symbol",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  }).format(number);
};
