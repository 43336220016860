import React from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  reportError,
  addBreadcrumb,
} from "../../../../../shared/error-monitoring";
import {
  handleEnterKey,
  preventChangeFocus,
} from "../../../../../shared/helpers";
import {
  RECAPTCHA_ACTION,
  MOBILE_VALIDATION_PATTERN,
  EMAIL_VALIDATION_PATTERN,
} from "../../../../constants/shared";
import {
  LEAD_SET_FIELDS,
  LEAD_SET_SUBMITTING,
  LEAD_SET_SUBMIT_ERROR,
  LEAD_SET_LEAD_ID,
  LEAD_SET_IS_OTP_STEP,
} from "../../../../reducers/actions";
import { submit } from "../../../../utils/api";
import { getRecaptchaToken } from "../../../../utils/recaptcha";
import LoadingIndicator from "../../../LoadingIndicator";

const PersonalInfoStep = () => {
  const firstName = useSelector((state) => state.leadForm.fields.firstName);
  const lastName = useSelector((state) => state.leadForm.fields.lastName);
  const email = useSelector((state) => state.leadForm.fields.email);
  const mobile = useSelector((state) => state.leadForm.fields.mobile);
  const toBeSubmitted = useSelector((state) => state.leadForm.fields);

  const isSubmitting = useSelector((state) => state.leadForm.isSubmitting);
  const submitError = useSelector((state) => state.leadForm.submitError);

  const currentStep = useSelector((state) => state.leadForm.currentStep);
  const isLoading = useSelector((state) => state.leadForm.stepLoading);
  const dispatch = useDispatch();

  const { register, trigger, errors } = useForm();

  const handleChange = (event) => {
    const tempFields = Object.assign({}, toBeSubmitted);

    tempFields[event.target.name] = event.target.value;

    dispatch({ type: LEAD_SET_FIELDS, payload: tempFields });
  };

  const handleValidation = async () => {
    let isFormValid = true;

    isFormValid = await trigger(["firstName", "lastName", "email", "mobile"]);

    return isFormValid;
  };

  const handleFormSubmit = async () => {
    addBreadcrumb("submitLead", "Lead Data", toBeSubmitted);

    const validationResult = await handleValidation();
    if (!validationResult) return;
    const submitData = Object.assign({}, toBeSubmitted);

    submitData.amount = parseFloat(submitData.amount);

    dispatch({ type: LEAD_SET_SUBMITTING, payload: true });
    dispatch({ type: LEAD_SET_SUBMIT_ERROR, payload: "" });

    try {
      const getToken = await getRecaptchaToken(RECAPTCHA_ACTION);

      if (!getToken.token) {
        reportError(getToken.error);
      }

      submitData.recaptchaToken = getToken.token;
      const submitToApi = await submit(submitData);

      dispatch({ type: LEAD_SET_LEAD_ID, payload: submitToApi.leadGuid });
      dispatch({ type: LEAD_SET_IS_OTP_STEP, payload: true });
    } catch (error) {
      dispatch({
        type: LEAD_SET_SUBMIT_ERROR,
        payload:
          "There's something wrong when submitting your request. Please try again.",
      });
      if (error instanceof Error) {
        reportError(error);
        return;
      }
      reportError(JSON.stringify(error));
    } finally {
      dispatch({ type: LEAD_SET_SUBMITTING, payload: false });
    }
  };

  const handleKeyDown = (event) => {
    handleEnterKey(event, () => {
      handleFormSubmit();
    });
  };

  return (
    <section
      className={`sqz-section ${
        currentStep === 9 && !isLoading ? "" : "sqz-hide"
      } sqz-last`}
      id="step-9"
      data-testid="form-step-9"
    >
      <div
        className="sqz-loader"
        style={{
          display: currentStep === 9 && isLoading ? "block" : "none",
        }}
        data-testid="loader"
      >
        <span className="sqz-loader_img_wrap">
          <img src="/assets/images/quest-loader.svg" alt="loader" />
          <p>
            <strong>Calculating...</strong>
          </p>
        </span>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10 text-center">
            <div className="sqz-question_header">
              <h2>Good job!</h2>
              <h4>Got your quote – See it now</h4>
            </div>
            <img
              src="/assets/images/tick.svg"
              alt="tick"
              className="sqz-tick"
            />
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="sqz-form_row text-left">
                  <input
                    type="text"
                    className="form-control required no-max-width"
                    name="firstName"
                    value={firstName}
                    data-testid="firstName"
                    onChange={handleChange}
                    placeholder="Full name"
                    id="sqz-field_10_1"
                    ref={register({
                      required: "Please fill out your full name",
                    })}
                    onKeyDown={handleKeyDown}
                  />
                  {errors.firstName && (
                    <label className="error">{errors.firstName.message}</label>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="sqz-form_row text-left">
                  <input
                    type="text"
                    className="form-control required no-max-width"
                    name="lastName"
                    value={lastName}
                    data-testid="lastName"
                    onChange={handleChange}
                    placeholder="Company name"
                    id="sqz-field_10_2"
                    ref={register({
                      required: "Please fill out your company name",
                    })}
                    onKeyDown={handleKeyDown}
                  />
                  {errors.lastName && (
                    <label className="error">{errors.lastName.message}</label>
                  )}
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="sqz-form_row text-left">
                  <input
                    type="email"
                    className="form-control required no-max-width"
                    name="email"
                    value={email}
                    data-testid="email"
                    onChange={handleChange}
                    placeholder="Email"
                    id="sqz-field_10_3"
                    ref={register({
                      required: "Please fill out your email address",
                      pattern: {
                        value: EMAIL_VALIDATION_PATTERN,
                        message: "Invalid email address",
                      },
                    })}
                    onKeyDown={handleKeyDown}
                  />
                  {errors.email && (
                    <label className="error">{errors.email.message}</label>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="sqz-form_row text-left">
                  <input
                    type="tel"
                    className="form-control required no-max-width"
                    name="mobile"
                    value={mobile}
                    data-testid="mobile"
                    onChange={handleChange}
                    placeholder="Mobile"
                    id="sqz-field_10_4"
                    maxLength={10}
                    ref={register({
                      required: "Please fill out your mobile phone number",
                      minLength: {
                        value: 10,
                        message:
                          "Invalid mobile number. Please use the following format: 04xxxxxxxx (e.g. 0412345678)",
                      },
                      pattern: {
                        value: MOBILE_VALIDATION_PATTERN,
                        message:
                          "Invalid mobile number. Please use the following format: 04xxxxxxxx (e.g. 0412345678)",
                      },
                    })}
                    onKeyDown={handleKeyDown}
                  />
                  {errors.mobile && (
                    <label className="error">{errors.mobile.message}</label>
                  )}
                </div>
              </div>
            </div>
            {submitError && (
              <div>
                <label className="error">{submitError}</label>
                <br />
              </div>
            )}
            <div className="submit-button-row">
              <button
                type="button"
                className="sqz-btn sqz-submit"
                onClick={handleFormSubmit}
                data-testid="submit"
                disabled={isSubmitting || currentStep !== 9}
                onKeyDown={preventChangeFocus}
              >
                Get my Loan options
              </button>{" "}
              {isSubmitting && <LoadingIndicator className="loading-spinner" />}
            </div>
            <div className="sqz-form_row">
              <p>
                By proceeding you agree to Quest’s
                <br />{" "}
                <a href="https://quest.finance/help-support/privacy-policy/">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="https://quest.finance/help-support/terms-conditions/">
                  Terms & Conditions
                </a>
              </p>
            </div>
            <button
              type="button"
              className="sqz-back"
              onClick={() => {
                dispatch({ type: "CHANGE_STEP", payload: 8 });
              }}
              onKeyDown={preventChangeFocus}
              data-testid={`goBackToStep${currentStep - 1}`}
            >
              Prev
            </button>
            <div className="sqz-form_row">
              <p>Your details are protected</p>
              <img
                src="/assets/images/McAfee-Logo@2x.png"
                alt="McAfee"
                width="80"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PersonalInfoStep;
