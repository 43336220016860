import React, { useEffect } from "react";
import PropTypes from "prop-types";
import InputField from "./input";
import backarrow from "../images/backarrowblack.png";
import logo from "../images/logo.svg";

const Fees = ({ onBack, onShowLoanSummary, formData, onFormDataChange }) => {
  useEffect(() => {
    console.log("formData updated:", formData);
  }, [formData]);

  const parseAmount = (value) => {
    return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
  };

  const calculateLoanAmount = (updatedFormData) => {
    const purchasePrice = parseAmount(updatedFormData.purchasePrice);
    const cashDeposit = parseAmount(updatedFormData.cashDeposit);
    const tradeIn = parseAmount(updatedFormData.tradeIn);
    const payoutAmount = parseAmount(updatedFormData.payoutAmount);
    const brokerOriginationFee = parseAmount(
      updatedFormData.brokerOriginationFee
    );
    const loanApplicationFee = parseAmount(updatedFormData.loanApplicationFee);

    let loanAmount = purchasePrice - cashDeposit - tradeIn + payoutAmount;

    // Add fees to loan amount if their respective checkboxes are checked
    if (updatedFormData.financeFees) {
      loanAmount += brokerOriginationFee * 1.1;
    }
    if (updatedFormData.financeLenderFee) {
      loanAmount += loanApplicationFee * 1.1;
    }

    return loanAmount;
  };

  const updateBrokerageFields = (updatedFormData) => {
    const loanAmount = calculateLoanAmount(updatedFormData);
    const brokerage = parseAmount(updatedFormData.brokerage);
    const brokeragePercentage = parseAmount(
      updatedFormData.brokeragePercentage
    );

    if (loanAmount > 0) {
      // If brokeragePercentage was changed, update brokerage amount
      if (
        updatedFormData.brokeragePercentage !== formData.brokeragePercentage
      ) {
        const calculatedBrokerage = (brokeragePercentage / 100) * loanAmount;
        updatedFormData.brokerage = isNaN(calculatedBrokerage)
          ? ""
          : formatMoney(calculatedBrokerage);
      }
      // If brokerage amount was changed, update percentage
      else if (updatedFormData.brokerage !== formData.brokerage) {
        const calculatedPercentage = (brokerage / loanAmount) * 100;
        updatedFormData.brokeragePercentage = isNaN(calculatedPercentage)
          ? ""
          : formatPercentage(calculatedPercentage);
      }
      // If neither was changed directly (e.g., checkbox toggle), update both based on current percentage
      else if (updatedFormData.brokeragePercentage) {
        const calculatedBrokerage = (brokeragePercentage / 100) * loanAmount;
        updatedFormData.brokerage = isNaN(calculatedBrokerage)
          ? ""
          : formatMoney(calculatedBrokerage);
      }
    } else {
      updatedFormData.brokerage = "";
      updatedFormData.brokeragePercentage = "";
    }

    return updatedFormData;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedFormData = { ...formData, [name]: value };

    // Always update brokerage fields when any fee-related field changes
    if (
      [
        "brokerage",
        "brokeragePercentage",
        "loanApplicationFee",
        "financeFees",
      ].includes(name)
    ) {
      updatedFormData = updateBrokerageFields(updatedFormData);
    }

    onFormDataChange(updatedFormData);
  };

  const formatMoney = (value) => {
    const number = parseFloat(value);
    return isNaN(number)
      ? ""
      : `$${number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const formatPercentage = (value) => {
    const number = parseFloat(value);
    return isNaN(number) ? "" : `${number.toFixed(2)}%`;
  };

  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;

    if (
      name === "brokerage" ||
      name === "loanApplicationFee" ||
      name === "financeFees"
    ) {
      formattedValue = formatMoney(value);
    } else if (name === "brokeragePercentage") {
      formattedValue = formatPercentage(value);
    }

    const updatedFormData = { ...formData, [name]: formattedValue };
    onFormDataChange(updatedFormData);
  };

  const handleCheckboxChange = (name, checked) => {
    console.log(`Checkbox changed in Fees: ${name} = ${checked}`);
    console.log("Current formData:", formData);

    console.log("name", name);
    let updatedFormData = { ...formData, [name]: checked };
    console.log("Updated formData before brokerage update:", updatedFormData);

    // Ensure we're using the correct property names
    if (name === "financeFees" || name === "financeLenderFee") {
      updatedFormData = updateBrokerageFields(updatedFormData);
    }

    console.log("Final updated formData:", updatedFormData);

    onFormDataChange(updatedFormData);
  };

  const inputFields = [
    {
      name: "brokerage",
      label: "Brokerage Fee",
      placeholder: "Enter Brokerage Fee",
      type: "money",
    },
    {
      name: "brokeragePercentage",
      label: "Brokerage Fee (%)",
      placeholder: "Enter Brokerage Fee Percentage",
      type: "percentage",
    },
    {
      name: "loanApplicationFee",
      label: "Loan Application Fee",
      placeholder: "Enter Loan Application Fee",
      type: "money",
    },
    {
      name: "brokerOriginationFee",
      label: "Origination Fee",
      placeholder: "Enter Origination Fee",
      type: "money",
    },
  ];

  return (
    <div className="iphone">
      <div className="div">
        <div
          style={{
            width: 26,
            height: 26,
            left: 3,
            top: 3,
            position: "relative",
            background: "white",
          }}
          onClick={onBack}
        >
          <img alt="Back" src={backarrow} />
        </div>
        <div className="navbar">
          <div className="navbar-item">
            <div className="ellipse" />
            <div className="text-wrapper-6">Loan</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-2 solid" />
            <div className="text-wrapper-7">Payment</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-3 solid" />
            <div className="text-wrapper-8">Fees</div>
          </div>
          <div className="navbar-item">
            <div className="ellipse-4" />
            <div className="text-wrapper-9">Quote</div>
          </div>
          <div className="line" />
          <div className="line-2" />
        </div>

        <div className="text-wrapper-4">Fees to be charged</div>

        <div className="frame">
          {inputFields.map((field, index) => (
            <InputField
              key={index}
              name={field.name}
              label={field.label}
              placeholder={field.placeholder}
              type={field.type}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
            />
          ))}
          <div className="text-wrapper">
            Which of these fees would you like to finance?
          </div>

          <InputField
            name="financeLenderFee"
            label="Lender Application Fee"
            type="checkbox"
            checked={formData.financeLenderFee || false}
            onChange={handleCheckboxChange}
          />
          <InputField
            name="financeFees"
            label="Lender Originatioin Fee"
            type="checkbox"
            checked={formData.financeFees || false}
            onChange={handleCheckboxChange}
          />
        </div>

        <div className="overlap" onClick={onShowLoanSummary}>
          <div className="text-wrapper-2">Next →</div>
        </div>

        <div className="group">
          <div className="text-wrapper-5">Powered by</div>
          <img className="image" alt="Powered by logo" src={logo} />
        </div>
      </div>
    </div>
  );
};

Fees.propTypes = {
  onBack: PropTypes.func.isRequired,
  onShowLoanSummary: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
};

export default Fees;
