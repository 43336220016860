import React from "react";
import PropTypes from "prop-types";
import CurrencyDisplay from "../../../shared/components/CurrencyDisplay";
import { VARIANT_OPTONS_ON_OUTPUT } from "../../constants/shared";
import { product } from "../../types";

const ProductCard = ({
  loanAmount,
  product,
  variant,
  optionNumber,
  bankStatementLink,
}) => {
  let header = (
    <img
      src={product.lender.logo}
      alt={product.lender.name}
      className="sqz-client_logo"
    />
  );

  if (variant === VARIANT_OPTONS_ON_OUTPUT) {
    header = <h3 className="option-text">Option {optionNumber}</h3>;
  }
  return (
    <div className="col-12 col-md-6 product-card">
      <div className="sqz-jump_box">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="sqz-loan_box text-center">
              {header}
              <p className="sqz-loan_amount">
                <CurrencyDisplay
                  value={loanAmount + product.applicationFee}
                  rounding={true}
                  prefix="$"
                />
              </p>
              <p>
                <span data-testid="product-name">{product.name}</span> <br />
                Loan Amount (inc fees)*
              </p>
            </div>
          </div>
          <div className="col-12 text-center">
            <div className="row justify-content-center">
              <div
                className={`${
                  variant === VARIANT_OPTONS_ON_OUTPUT ? "col-xl-4" : "col-xl-3"
                } col-4`}
              >
                <div className="sqz-item">
                  <h4>
                    <CurrencyDisplay value={product.monthlyRepaymentAmount} />
                  </h4>
                  <p>
                    Monthly
                    <br />
                    payments
                  </p>
                </div>
              </div>
              <div
                className={`${
                  variant === VARIANT_OPTONS_ON_OUTPUT ? "col-xl-4" : "col-xl-3"
                } col-4`}
              >
                <div className="sqz-item">
                  <h4>{product.terms[0].label}</h4>
                  <p>
                    Loan
                    <br />
                    term
                  </p>
                </div>
              </div>
              <div
                className={`${
                  variant === VARIANT_OPTONS_ON_OUTPUT ? "col-xl-4" : "col-xl-2"
                } col-4`}
              >
                <div className="sqz-item">
                  <h4>
                    <CurrencyDisplay value={product.applicationFee} />
                  </h4>
                  <p>
                    Application
                    <br />
                    fee
                  </p>
                </div>
              </div>
              {variant !== VARIANT_OPTONS_ON_OUTPUT && (
                <div className="col-xl-2 col-4">
                  <div className="sqz-item">
                    <h4>
                      <CurrencyDisplay value={product.monthlyFee} />
                    </h4>
                    <p>
                      Monthly
                      <br />
                      fee
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col cta-container">
                <p className="mt-40">
                  <img
                    className="icon-lock"
                    src="/assets/images/icon-lock.svg"
                    alt="icon-lock"
                  />{" "}
                  SECURITY GUARANTEE
                </p>
                <a href={bankStatementLink} className="sqz-btn mt-0">
                  Link Bank Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  loanAmount: PropTypes.number,
  product,
  variant: PropTypes.string,
  optionNumber: PropTypes.number,
  bankStatementLink: PropTypes.string,
};

export default ProductCard;
