import React, { useState } from "react";
import "./css/ToggleSwitch.css"; // Import the CSS for styling
import PropTypes from "prop-types";

const ToggleSwitch = ({ options = [], initialOption = "", onChange }) => {
  ToggleSwitch.propTypes = {
    options: PropTypes.array.isRequired,
    initialOption: PropTypes.string,
    onChange: PropTypes.func,
  };
  const [selectedOption, setSelectedOption] = useState(
    initialOption || options[0]
  );

  // Handle toggle between options
  const handleToggle = (option) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <div className="toggle-switch">
      {options.map((option) => (
        <div
          key={option}
          className={`toggle-option ${
            selectedOption === option ? "active" : ""
          }`}
          onClick={() => handleToggle(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

export default ToggleSwitch;
