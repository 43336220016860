import PropTypes from "prop-types";

export const product = PropTypes.shape({
  applicationFee: PropTypes.number,
  interestRate: PropTypes.number,
  lender: PropTypes.shape({
    logo: PropTypes.string,
  }),
  monthlyFee: PropTypes.number,
  monthlyRepaymentAmount: PropTypes.number,
  name: PropTypes.string,
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
});

export const colorScheme = PropTypes.shape({
  progressBar: PropTypes.string.isRequired,
  progressBarDots: PropTypes.string.isRequired,
});

export const partner = PropTypes.shape({
  slug: PropTypes.string,
  name: PropTypes.string,
  logoFileName: PropTypes.string,
  websiteUrl: PropTypes.string,
  colorScheme,
});
