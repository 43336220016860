import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CurrencyDisplay from "../shared/components/CurrencyDisplay";
import CurrencyInput from "../shared/components/CurrencyInput";
import RadioGroup from "../shared/components/RadioGroup";
import { MAXIMUM_LOAN_AMOUNT, MINIMUM_LOAN_AMOUNT } from "../shared/constants";
import { convertToCurrency, redirectParentWindow } from "../shared/helpers";
import calculateRepayment from "../shared/helpers/repayment-calculator";
import NiceSelect from "./components/NiceSelect";

const maxInterestRate = 16;

const LoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(50000);
  const [loanTerm, setLoanTerm] = useState(60);
  const [interestRate, setInterestRate] = useState(5);
  const [balloonRepayment, setBalloonRepayment] = useState(0);
  const [repaymentFrequency, setRepaymentFrequency] = useState("weekly");
  const [repaymentEstimation, setRepaymentEstimation] = useState(0);
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_BASE_URL}?source=webform_loan_calculcator`
  );
  const { register, trigger, errors } = useForm();

  const termOptions = [
    {
      label: "1 Year",
      value: 12,
    },
    {
      label: "2 Years",
      value: 24,
    },
    {
      label: "3 Years",
      value: 36,
    },
    {
      label: "4 Years",
      value: 48,
    },
    {
      label: "5 Years",
      value: 60,
    },
    {
      label: "6 Years",
      value: 72,
    },
    {
      label: "7 Years",
      value: 84,
    },
  ];

  const repaymentFrequencyOptions = [
    {
      label: "Monthly",
      value: "monthly",
    },
    {
      label: "Fortnightly",
      value: "fortnightly",
    },
    {
      label: "Weekly",
      value: "weekly",
    },
  ];

  const minimumAmountCurrency = convertToCurrency(MINIMUM_LOAN_AMOUNT);
  const maximumAmountCurrency = convertToCurrency(MAXIMUM_LOAN_AMOUNT);

  register("loanAmount", {
    validate: {
      min: () => {
        return (
          loanAmount >= MINIMUM_LOAN_AMOUNT ||
          `Minimum loan amount is ${minimumAmountCurrency}`
        );
      },
      max: () => {
        return (
          loanAmount <= MAXIMUM_LOAN_AMOUNT ||
          `Maximum loan amount is ${maximumAmountCurrency}`
        );
      },
    },
  });

  useEffect(() => {
    const captureQueryParams = async () => {
      let queryParams = window.location.search;

      if (queryParams) {
        queryParams = queryParams.replace("?", "");
        await setUrl((prevState) => {
          return prevState + "&" + queryParams;
        });
      }
    };

    captureQueryParams();
  }, [setUrl]);

  useEffect(() => {
    let maxBalloonRepayment = 65;
    if (loanTerm === 60 || loanTerm === 72 || loanTerm === 84) {
      maxBalloonRepayment = 40;
    } else if (loanTerm === 48) {
      maxBalloonRepayment = 45;
    } else if (loanTerm === 36) {
      maxBalloonRepayment = 45;
    }
    register("balloonRepayment", {
      validate: {
        min: () => {
          return balloonRepayment >= 0 || "Minimum balloon repayment is 0%";
        },
        max: () => {
          return (
            balloonRepayment <= maxBalloonRepayment ||
            `Maximum balloon repayment is ${maxBalloonRepayment}%`
          );
        },
      },
    });
  }, [loanTerm, register, balloonRepayment]);

  useEffect(() => {
    const updatePaymentEstimation = async () => {
      const valid = await trigger([
        "loanAmount",
        "interestRate",
        "balloonRepayment",
      ]);

      if (valid) {
        setRepaymentEstimation(
          calculateRepayment(
            loanAmount,
            loanTerm,
            interestRate,
            balloonRepayment,
            repaymentFrequency
          )
        );
      } else {
        if (errors.loanAmount?.type === "max") {
          setLoanAmount(MAXIMUM_LOAN_AMOUNT);
        }

        if (errors.interestRate?.type === "max") {
          setInterestRate(maxInterestRate);
        }

        if (errors.balloonRepayment?.type === "max") {
          if (loanTerm === 60 || loanTerm === 72 || loanTerm === 84) {
            setBalloonRepayment(40);
          } else if (loanTerm === 48) {
            setBalloonRepayment(45);
          } else if (loanTerm === 36) {
            setBalloonRepayment(55);
          } else {
            setBalloonRepayment(65);
          }
        }
      }
    };

    updatePaymentEstimation();
  }, [
    loanAmount,
    loanTerm,
    interestRate,
    balloonRepayment,
    repaymentFrequency,
    trigger,
    register,
    errors,
  ]);

  function handleChange(event) {
    if (event.target.dataset.name === "loanTerm") {
      setLoanTerm(parseInt(event.target.dataset.value));
    } else if (event.target.name === "interestRate") {
      setInterestRate(event.target.value);
    } else if (event.target.name === "balloonRepayment") {
      setBalloonRepayment(event.target.value);
    } else {
      setRepaymentFrequency(event.target.value);
    }
  }

  return (
    <div className="sqz-calc_wrap text-center">
      <form id="sqz-calculator" action="/" noValidate>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="sqz-form_row">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label>Loan Amount</label>
                    <div className="sqz-currency sqz-has_dollar">
                      <CurrencyInput
                        className="form-control required"
                        name="loanAmount"
                        value={loanAmount}
                        onValueChange={({ value }) => {
                          setLoanAmount(value);
                        }}
                        testId="amount"
                      />
                      {errors.loanAmount && (
                        <label className="error" data-testid="loanAmountError">
                          {errors.loanAmount.message}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Loan Term</label>
                    <NiceSelect
                      name="loanTerm"
                      options={termOptions}
                      onChange={handleChange}
                      selected={loanTerm}
                      testId="loanTerm"
                      useScroll={true}
                    />
                  </div>
                </div>
              </div>
              <div className="sqz-form_row">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label>Interest rate</label>
                    <div className="sqz-has_percentage">
                      <input
                        inputMode="decimal"
                        name="interestRate"
                        className="form-control required"
                        value={interestRate}
                        onChange={handleChange}
                        data-testid="interestRate"
                        ref={register({
                          validate: {
                            required: () => {
                              return (
                                Boolean(interestRate) ||
                                "Interest rate is required"
                              );
                            },
                            min: () => {
                              return (
                                interestRate >= 0 ||
                                "Minimum interest rate is 1%"
                              );
                            },
                            max: () => {
                              return (
                                interestRate <= maxInterestRate ||
                                `Maximum interest rate is ${maxInterestRate}%`
                              );
                            },
                          },
                        })}
                      />
                      {(errors.interestRate?.type === "required" ||
                        errors.interestRate?.type === "min") && (
                        <label
                          className="error"
                          data-testid="interestRateError"
                        >
                          {errors.interestRate.message}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Balloon Repayment</label>
                    <div className="sqz-has_percentage">
                      <input
                        inputMode="decimal"
                        name="balloonRepayment"
                        className="form-control required"
                        value={balloonRepayment}
                        onChange={handleChange}
                        data-testid="balloonRepayment"
                      />
                      {errors.balloonRepayment?.type === "min" && (
                        <label
                          className="error"
                          data-testid="balloonRepaymentError"
                        >
                          {errors.balloonRepayment.message}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="sqz-form_row mb-0">
                <label>Repayment frequency</label>
                <RadioGroup
                  name="repaymentFrequency"
                  options={repaymentFrequencyOptions}
                  checked={repaymentFrequency}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="sqz-loan_box text-center">
                <div className="sqz-box_inner">
                  <img src="/assets/images/be-smiley.svg" alt="smiley" />
                  <p>Your estimated repayments are</p>
                  <p
                    className="sqz-loan_amount"
                    data-testid="repaymentsEstimation"
                  >
                    <CurrencyDisplay
                      value={repaymentEstimation}
                      rounding={true}
                    />
                  </p>
                  <p>{repaymentFrequency}</p>
                  <button
                    type="button"
                    className="sqz-btn"
                    onClick={() => redirectParentWindow(url)}
                    data-testid="redirectParentButton"
                  >
                    Get a personalised quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoanCalculator;
