export const partners = [
  {
    slug: "eurodrive-centre",
    name: "EuroDrive Centre",
    logoFileName: "",
    websiteUrl: "",
    colorScheme: {
      progressBar: "#ff243a",
      progressBarDots: "#b21c2b",
    },
  },
  {
    slug: "plan-a-mortgage",
    name: "PLAN A Mortgage",
    logoFileName: "",
    websiteUrl: "",
    colorScheme: {
      progressBar: "#ff243a",
      progressBarDots: "#b21c2b",
    },
  },
  {
    slug: "superior-home-loans",
    name: "Superior Home Loans",
    logoFileName: "",
    websiteUrl: "",
    colorScheme: {
      progressBar: "#05f58f",
      progressBarDots: "#01d77b",
    },
  },
];
