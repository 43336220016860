import {
  LEAD_CHANGE_STEP,
  LEAD_SET_STEP_LOADING,
  LEAD_SET_IS_OTP_STEP,
  LEAD_SET_LEAD_ID,
  LEAD_SET_FIELDS,
  LEAD_SET_SUBMIT_ERROR,
  LEAD_SET_SUBMITTING,
  LEAD_SET_PREFILL_FIELD,
  LEAD_SET_FORM_ERRORS,
} from "./actions";

export const leadFormState = {
  currentStep: 1,
  stepsCount: 9,
  stepLoading: true,
  formStyles: {},
  isSubmitting: false,
  submitError: "",
  fields: {
    amount: "",
    purpose: "",
    goals: [],
    period: "",
    creditHistory: "",
    neededBy: "",
    businessAgeMonth: 0,
    monthlyTurnover: 0,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    source: "",
    utmSource: "",
    utmCampaign: "",
    utmMedium: "",
    utmTerm: "",
    utmContent: "",
    httpReferrer: "",
    ref: "",
    variant: "",
  },
  isOtpStep: false,
  leadGuid: "",
  prefillField: {
    fieldName: "",
    fieldValue: "",
  },
  formErrors: null,
};

const leadFormReducer = (state = leadFormState, action) => {
  switch (action.type) {
    case LEAD_CHANGE_STEP: {
      const stepNumber = action.payload;
      const nextPage = stepNumber - 1;
      let stepLoading = false;
      if (state.currentStep < stepNumber) stepLoading = true;
      return {
        ...state,
        currentStep: stepNumber,
        formStyles: {
          transform: `translateX(-${nextPage}00%)`,
        },
        stepLoading,
      };
    }
    case LEAD_SET_STEP_LOADING: {
      return {
        ...state,
        stepLoading: action.payload,
      };
    }
    case LEAD_SET_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.payload,
      };
    }
    case LEAD_SET_SUBMIT_ERROR: {
      return {
        ...state,
        submitError: action.payload,
      };
    }
    case LEAD_SET_FIELDS: {
      const fields = { ...state.fields, ...action.payload };

      return {
        ...state,
        fields,
      };
    }
    case LEAD_SET_LEAD_ID: {
      return {
        ...state,
        leadGuid: action.payload,
      };
    }
    case LEAD_SET_IS_OTP_STEP: {
      return {
        ...state,
        isOtpStep: action.payload,
      };
    }
    case LEAD_SET_PREFILL_FIELD: {
      return {
        ...state,
        prefillField: action.payload,
      };
    }
    case LEAD_SET_FORM_ERRORS: {
      return {
        ...state,
        formErrors: action.payload,
      };
    }

    default:
      return state;
  }
};

export default leadFormReducer;
