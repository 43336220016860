import React from "react";
import dayjs from "dayjs";

import "./footer.scss";

const Footer = () => {
  return (
    <div className="template-footer">
      &copy; Copyright {dayjs().format("YYYY")} Oneteam Capital Pty Ltd T/as
      Quest Finance Technologies.
    </div>
  );
};

export default Footer;
