import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import QuoteForm from "../../components/QuoteForm";
import QuoteOutput from "../../components/QuoteOutput";
import Header from "./components/Header";
import { colorScheme } from "./constants";

const DefaultQuest = () => {
  const history = useHistory();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loanAmount, setLoanAmount] = useState(0);
  const [results, setResults] = useState([]);

  const handleResults = (newResult, loanAmount) => {
    setResults(newResult);
    setIsSubmitted(true);
    setLoanAmount(loanAmount);
    history.push("#result");
  };

  return (
    <div className="sqz-wrapper">
      <Header />
      <React.Fragment>
        {isSubmitted ? (
          <QuoteOutput loanAmount={loanAmount} products={results} />
        ) : (
          <QuoteForm handleResults={handleResults} colorScheme={colorScheme} />
        )}
      </React.Fragment>
    </div>
  );
};

export default DefaultQuest;
