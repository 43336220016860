import React from "react";

const Header = () => {
  return (
    <header className="sqz-header">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-4">
            <div className="sqz-logo">
              <a href={process.env.REACT_APP_WEBSITE_URL}>
                <img src="/assets/images/quest-logo.svg" alt="quest" />
              </a>
            </div>
          </div>
          <div className="col-8 text-right">
            <ul className="sqz-nav">
              <li>
                <a
                  href="tel:1300 465 363"
                  className="sqz-btn sqz-primary_outline sqz-call-icon"
                >
                  <img
                    src="/assets/images/icon-call.svg"
                    className="call-icon"
                    alt="call icon"
                  />
                  1300 465 363
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
