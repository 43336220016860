/**
 * Calculates the interest payment for a given period of an amortized loan.
 * @param {number} rate - The interest rate per period.
 * @param {number} per - The period for which you want to calculate the interest.
 * @param {number} nper - The total number of payment periods.
 * @param {number} pv - The present value (or loan amount).
 * @param {number} [fv=0] - The future value (default is 0).
 * @param {number} [type=0] - When payments are due: 0 = end of period (default), 1 = beginning of period.
 * @returns {number} The interest payment for the specified period.
 */
function ipmt(rate, period, periods, present, future, type) {
  // Credits: algorithm inspired by Apache OpenOffice

  // Use existing type or default to 0
  type = typeof type !== "undefined" ? type : 0;

  // Evaluate rate and periods (TODO: replace with secure expression evaluator)
  rate = eval(rate); // eslint-disable-line no-eval
  periods = eval(periods); // eslint-disable-line no-eval

  // Compute payment
  var payment = pmt(rate, periods, present, future, type);

  // Compute interest
  var interest;
  if (period === 1) {
    if (type === 1) {
      interest = 0;
    } else {
      interest = -present;
    }
  } else {
    if (type === 1) {
      interest =
        getFvDuePayments(rate, period - 2, payment, present, 1) - payment;
    } else {
      interest = getFvDuePayments(rate, period - 1, payment, present, 0);
    }
  }

  // Return interest
  return interest * rate;
}

/**
 * Calculates the payment for a loan based on constant payments and a constant interest rate.
 * @param {number} rate - The interest rate per period.
 * @param {number} nper - The total number of payment periods.
 * @param {number} pv - The present value (or loan amount).
 * @param {number} [fv=0] - The future value (default is 0).
 * @param {number} [type=0] - When payments are due: 0 = end of period (default), 1 = beginning of period.
 * @returns {number} The payment amount per period.
 */
function pmt(rate, nper, pv, fv = 0, type = 0) {
  let payment;
  if (rate === 0) {
    payment = (pv + fv) / nper;
  } else {
    const term = Math.pow(1 + rate, nper);
    if (type === 1) {
      payment =
        ((fv * rate) / (term - 1) + (pv * rate) / (1 - 1 / term)) / (1 + rate);
    } else {
      payment = (fv * rate) / (term - 1) + (pv * rate) / (1 - 1 / term);
    }
  }
  return -payment;
}

/**
 * Helper function to calculate the future value of a series of payments.
 * @param {number} rate - The interest rate per period.
 * @param {number} nper - The total number of payment periods.
 * @param {number} pmt - The payment amount per period.
 * @param {number} pv - The present value.
 * @param {number} type - When payments are due: 0 = end of period, 1 = beginning of period.
 * @returns {number} The future value.
 */
function getFvDuePayments(rate, nper, pmt, pv, type) {
  if (rate === 0) {
    return -(pv + pmt * nper);
  } else {
    const term = Math.pow(1 + rate, nper);
    if (type === 1) {
      return -(pv * term + (pmt * (1 + rate) * (term - 1)) / rate);
    } else {
      return -(pv * term + (pmt * (term - 1)) / rate);
    }
  }
}
/**
 * Calculates the total interest paid over the entire loan term.
 * @param {number} rate - The interest rate per period.
 * @param {number} nper - The total number of payment periods.
 * @param {number} pv - The present value (or loan amount).
 * @param {number} [fv=0] - The future value (default is 0).
 * @param {number} [type=0] - When payments are due: 0 = end of period (default), 1 = beginning of period.
 * @returns {number} The total interest paid over the loan term.
 */
function totalInterest(annualRate, nper, pv, fv = 0, type = 0) {
  // Convert annual rate to monthly rate
  const monthlyRate = annualRate / 12;

  const payment = pmt(monthlyRate, nper, pv, fv, type);
  let totalInterest = 0;
  let balance = pv;

  for (let per = 1; per <= nper; per++) {
    const interestPayment = balance * monthlyRate;
    const principalPayment = payment - interestPayment;

    totalInterest += interestPayment;
    balance -= principalPayment;

    if (Math.abs(balance) < 0.000001) break; // To handle floating point precision issues
  }

  return Math.abs(totalInterest);
}

/**
 * Calculates the commission for each payment based on the interest component.
 * @param {number} commissionRate - The commission rate (Y17 in your formula).
 * @param {number} totalFinanceRate - The total finance rate (B9 in your formula).
 * @param {number} interestPayment - The interest component of an individual payment (C25 in your formula).
 * @returns {number} The calculated commission, rounded to 2 decimal places.
 */
function calculateCommission(
  commissionRate,
  totalFinanceRate,
  interestPayment
) {
  const commissionPercentage =
    (commissionRate / (totalFinanceRate / 100)) * interestPayment;
  return Math.round(-commissionPercentage);
}

/**
 * Calculates the repayment schedule including interest, principal, and commission for each payment.
 * @param {number} rate - The interest rate per period.
 * @param {number} nper - The total number of payment periods.
 * @param {number} pv - The present value (or loan amount).
 * @param {number} commissionRate - The commission rate.
 * @param {number} totalFinanceRate - The total finance rate.
 * @param {number} [fv=0] - The future value (default is 0).
 * @param {number} [type=0] - When payments are due: 0 = end of period (default), 1 = beginning of period.
 * @returns {Array} An array of objects containing payment details for each period.
 */
function calculateRepaymentSchedule(
  rate,
  nper,
  pv,
  commissionRate,
  totalFinanceRate,
  fv = 0,
  type = 0
) {
  const payment = pmt(rate, nper, pv, fv, type);
  let balance = pv;
  let interest = 0;

  const schedule = [];
  let totalCommission = 0;

  for (let period = 1; period <= nper; period++) {
    const interestPayment = balance * rate;
    const principalPayment = payment + interestPayment;
    const commission = calculateCommission(
      commissionRate,
      totalFinanceRate,
      interestPayment
    );
    totalCommission += commission;
    schedule.push({
      period,
      payment: Math.abs(payment),
      interestPayment: Math.abs(interestPayment),
      principalPayment: Math.abs(principalPayment),
      commission,
      remainingBalance: balance - principalPayment,
    });
    balance += principalPayment;
    interest += Math.abs(interestPayment);
    if (Math.abs(balance) < 0.000001) break; // To handle floating point precision issues
  }

  return [totalCommission, interest];
}

export {
  ipmt,
  pmt,
  totalInterest,
  calculateCommission,
  calculateRepaymentSchedule,
};
