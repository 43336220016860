export const loadRecaptcha = () => {
  const script = document.createElement("script");
  script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITEKEY}`;

  document.body.appendChild(script);
};

export const getRecaptchaToken = async (action) => {
  return new Promise((resolve) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(async () => {
        try {
          const getToken = await window.grecaptcha.execute(
            process.env.REACT_APP_RECAPTCHA_SITEKEY,
            {
              action,
            }
          );

          if (!getToken) {
            resolve({
              error: "Failed to get recaptcha token",
            });
          }

          resolve({
            token: getToken,
          });
        } catch {
          resolve({
            error: "Failed to get recaptcha token",
          });
        }
      });
    } else {
      resolve({
        error: "Failed to get recaptcha token",
      });
    }
  });
};
