import * as yup from "yup";

export const PROVIDER = {
  DOCTOR: "DR",
  THERAPIST: "THERAPIST",
  NURSE: "NURSE",
  WELLNESS_FITNESS: "WELLNESS_FITNESS",
};

export const PROVIDER_LABEL = {
  [PROVIDER.THERAPIST]: "Beauty Therapist",
  [PROVIDER.NURSE]: "Nurse",
  [PROVIDER.DOCTOR]: "Dr",
  [PROVIDER.WELLNESS_FITNESS]: "Wellness / Fitness",
};

export const loanTerms = [1, 2, 3, 4, 5, 6, 7];

export const defaultValue = {
  description: "",
  cost: "",
  loanTerm: loanTerms[4],
  provider: PROVIDER.THERAPIST,
  chargePerTreatment: "",
  customerName: "",
  "week-1": 4,
  "week-2": 7,
  "week-3": 15,
};

const weekValidation = yup.number().required().min(1);

export const validationSchema = yup.object().shape({
  description: yup.string().max(150),
  loanTerm: yup.number().min(1).max(7).required(),
  chargePerTreatment: yup.number().required(),
  cost: yup.number().required(),
  provider: yup.string().max(150),
  customerName: yup.string().max(150),
  "week-1": weekValidation,
  "week-2": weekValidation,
  "week-3": weekValidation,
});
