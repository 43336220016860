import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { preventChangeFocus } from "../../../../../shared/helpers";
import {
  LEAD_SET_FIELDS,
  LEAD_CHANGE_STEP,
  LEAD_SET_FORM_ERRORS,
} from "../../../../reducers/actions";
import CustomRadio from "../../../CustomRadio";

const SelectionStep = ({
  stepName,
  options,
  stepNumber,
  previousStep,
  nextStep,
  header,
}) => {
  const stepFieldValue = useSelector(
    (state) => state.leadForm.fields[stepName]
  );
  const currentStep = useSelector((state) => state.leadForm.currentStep);
  const isLoading = useSelector((state) => state.leadForm.stepLoading);
  const formErrors = useSelector((state) => state.leadForm.formErrors);
  const dispatch = useDispatch();

  const btnRef = useRef();

  const prefilledFieldName = useSelector(
    (state) => state.leadForm.prefillField.fieldName
  );
  const prefilledFielValue = useSelector(
    (state) => state.leadForm.prefillField.fieldValue
  );

  const changeValue = async (value) => {
    const payload = { [stepName]: value };
    dispatch({
      type: LEAD_SET_FIELDS,
      payload,
    });
    dispatch({
      type: LEAD_CHANGE_STEP,
      payload: nextStep,
    });
  };

  useEffect(() => {
    if (prefilledFieldName === stepName) {
      const selectedOption = options.filter(
        (option) => option.value === prefilledFielValue
      )[0];
      if (selectedOption) {
        dispatch({
          type: LEAD_SET_FORM_ERRORS,
          payload: null,
        });

        btnRef.current
          .querySelector(`label[data-value="${selectedOption.value}"]`)
          .click();
      } else {
        dispatch({
          type: LEAD_SET_FORM_ERRORS,
          payload: {
            purpose: {
              message: "Invalid selection value",
            },
          },
        });
      }
    }
  }, [prefilledFieldName, prefilledFielValue, options, stepName, dispatch]);

  return (
    <section
      className={`sqz-section ${
        currentStep === stepNumber && !isLoading ? "" : "sqz-hide"
      }`}
      id={`step-${stepNumber}`}
      data-testid={`form-step-${stepNumber}`}
    >
      <img
        src="/assets/images/typing-bubble.svg"
        alt="typing-bubble"
        className="sqz-typing_bubble"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10 text-center">
            <div className="sqz-question_header">{header}</div>
            <div className="sqz-form_row">
              <CustomRadio
                name={stepName}
                options={options}
                checked={stepFieldValue}
                onClick={(value) => changeValue(value)}
                btnRef={btnRef}
              />
            </div>
            {formErrors !== null &&
              typeof formErrors[stepName] !== "undefined" && (
                <label className="error">{formErrors[stepName].message}</label>
              )}
            <br />
            <button
              type="button"
              className="sqz-back"
              onClick={() => {
                dispatch({ type: LEAD_CHANGE_STEP, payload: previousStep });
              }}
              data-testid={`goback-to-step${previousStep}`}
              onKeyDown={preventChangeFocus}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

SelectionStep.propTypes = {
  stepName: PropTypes.string,
  options: PropTypes.array,
  stepNumber: PropTypes.number,
  previousStep: PropTypes.number,
  nextStep: PropTypes.number,
  header: PropTypes.any,
  btnRef: PropTypes.object,
};

export default SelectionStep;
