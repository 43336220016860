import React from "react";
import PropTypes from "prop-types";

const CustomRadio = ({
  name,
  options,
  checkedValue,
  onClick,
  testId,
  btnRef,
}) => {
  return (
    <ul className="sqz-select_buttons row" ref={btnRef}>
      {options.map((item, index) => {
        return (
          <li key={index} className="col-12 col-md-6">
            <input
              type="radio"
              name={name}
              defaultChecked={checkedValue === item.value}
              id={`${name}-${index}`}
              value={item.value}
              className="required"
            />
            <label
              className="sqz-sel_btn"
              htmlFor={`${name}-${index}`}
              data-value={item.value}
              onClick={() => onClick(item.value)}
              data-testid={
                typeof testId !== "undefined"
                  ? `${testId}-${index}`
                  : `${name}-${index}`
              }
            >
              {item.label}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

CustomRadio.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  checkedValue: PropTypes.any,
  testId: PropTypes.string,
  onClick: PropTypes.func,
  btnRef: PropTypes.object,
};

export default CustomRadio;
