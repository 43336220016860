import React from "react";
import "./index.css";
import PropTypes from "prop-types";

const LoadingIndicator = ({ className }) => {
  return (
    <div className={className}>
      <svg
        className={`spinner`}
        width="30px"
        height="30px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="circle"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  );
};

LoadingIndicator.propTypes = {
  className: PropTypes.string,
};

export default LoadingIndicator;
