// for mobile
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import EmailQuoteModal from "./EmailQuoteModal";
import InfoBox from "./infobox";
import backarrow from "../images/backarrow.png";
import emailIcon from "../images/emailIcon_white.png";
import logo from "../images/QuestQ.svg";
import * as payments from "../modules/payments";

const LoanSummary = ({ onBack, formData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [calculations, setCalculations] = useState({
    purchasePrice: 0,
    cashDeposit: 0,
    tradeIn: 0,
    payoutAmount: 0,
    netAmountFinanced: 0,
    originationFee: 0,
    loanApplicationFee: 0,
    totalLoanAmount: 0,
    balloonPayment: 0,
    periodicPayment: 0,
    brokerage: 0,
    paymentTime: "Advance",
  });

  const [emailAddress, setEmailAddress] = useState("");

  // Helper function to format money
  const formatMoney = (amount) => {
    return new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(amount);
  };

  useEffect(() => {
    if (formData && Object.keys(formData).length > 0) {
      const calculateSummary = () => {
        // Helper function to parse amounts
        const parseAmount = (value) => {
          if (typeof value === "string") {
            return parseFloat(value.replace(/[^0-9.-]+/g, "")) || 0;
          }
          return parseFloat(value) || 0;
        };

        // Extract values from formData
        const purchasePrice = parseAmount(formData.purchasePrice);

        const cashDeposit = parseAmount(formData.cashDeposit);
        const tradeIn = parseAmount(formData.tradeIn);
        const payoutAmount = parseAmount(formData.payoutAmount);
        const financierRate = parseAmount(formData.interestRate);
        const term = parseInt(formData.loanTerm) || 1;
        const paymentCycle = formData.paymentFrequency;
        const paymentTime = formData.paymentTiming;

        // Calculate fees
        /*
balloonPayment
: 
"$3,199.00"
balloonPaymentPercentage
: 
"9.14"
brokerOriginationFee
: 
"495"
brokerage
: 
"$2,488.12"
brokeragePercentage
: 
"7.00%"
cashDeposit
: 
"$5,000.00"
financeBrokerFee
: 
false
financeFees
: 
true
financeLenderFee
: 
false
interestRate
: 
"10.00%"
loanApplicationFee
: 
""
loanTerm
: 
"5"
paymentFrequency
: 
"Monthly"
paymentTiming
: 
"Advance"
payoutAmount
: 
""
purchasePrice
: 
"$50,000.00"
tradeIn
: 
"$10,000.00"
        */

        // Calculate net amount financed
        let netAmountFinanced =
          purchasePrice - cashDeposit - tradeIn + payoutAmount;
        let netFinanceNoFees =
          purchasePrice - cashDeposit - tradeIn + payoutAmount;
        console.log("netAmountFinanced before fees", netAmountFinanced);

        // Add fees to net amount financed
        if (formData.financeFees === true || formData.financeFees === "true") {
          netAmountFinanced += parseAmount(formData.brokerOriginationFee) * 1.1;
        }

        if (
          formData.financeLenderFee === true ||
          formData.financeLenderFee === "true"
        ) {
          netAmountFinanced += parseAmount(formData.loanApplicationFee) * 1.1;
        }
        // determine the brokerage after fees have been added to the net amount financed.
        let brokerage = 0;
        // calculate the brokerage payable. We will need to store a temp val to hold the netFinanced amount.
        if (formData.brokeragePercentage !== "") {
          const brokeragePercentage = parseAmount(formData.brokeragePercentage);
          brokerage = (brokeragePercentage / 100) * netAmountFinanced * 1.1;
        } else {
          brokerage = parseAmount(formData.brokerage) * 1.1;
        }

        console.log("brokerage", brokerage);
        // Calculate total loan amount
        const totalLoanAmount = netAmountFinanced + brokerage;

        // Calculate balloon payment
        let balloonPayment = 0;
        if (formData.balloonPaymentPercentage !== "") {
          const balloonPercentage = parseAmount(
            formData.balloonPaymentPercentage
          );
          balloonPayment = (balloonPercentage / 100) * netFinanceNoFees;
        } else {
          balloonPayment = parseAmount(formData.balloonPayment);
        }

        // Calculate repayments
        const paymentsPerYear = paymentCycle === "Monthly" ? 12 : 52;
        const totalPayments = term * paymentsPerYear;
        const ratePerPeriod = financierRate / 100 / paymentsPerYear;
        // logout payment monthly params
        console.log("##########################################");
        console.log("PRIOR TO DETERMING THE REPAYMENT AMOUNT");
        console.log("ratePerPeriod", ratePerPeriod);
        console.log("totalPayments", totalPayments);
        console.log("netAmountFinanced", netAmountFinanced);
        console.log("balloonPayment", balloonPayment);
        console.log("type", paymentTime);
        console.log("brokerage", brokerage);
        console.log("Net Amount Finance", -netFinanceNoFees - brokerage);
        console.log("total Loan Amount", totalLoanAmount);
        console.log("##########################################");
        // 0 is arrears and 1 is advance
        let paymenTiming = 0;
        if (paymentTime === "Advance") {
          paymenTiming = 1;
        } else {
          paymenTiming = 0;
        }
        const paymentMonthly = payments.pmt(
          ratePerPeriod,
          totalPayments,
          -totalLoanAmount,
          balloonPayment,
          paymenTiming
        );

        console.log("################################");
        console.log("Repayment Monthly: ", paymentMonthly);
        console.log("total payments", totalPayments);
        console.log("net amount financed", netAmountFinanced);
        console.log("################################");

        // lets calculate the total interest using ipmt
        let interestSum = 0;
        for (let period = 0; period <= totalPayments; period++) {
          interestSum += Math.abs(
            payments.ipmt(
              ratePerPeriod,
              period,
              totalPayments,
              -totalLoanAmount
            )
          );
        }
        console.log("interestSum", interestSum);
        // lets calculate the commission for quest. First we need to work out the total interest earnt over the term of the loan.
        const questCommission = interestSum * (0.03 / (financierRate / 100));

        console.log("Quest Commission Calculation:");
        console.log("Total Interest:", interestSum);
        console.log("Financier Rate:", financierRate);
        console.log("Quest Commission:", questCommission);
        console.log("################################");
        console.log("totalInterest", interestSum);
        //logout all variables

        console.log("brokerageFeeWithGST", brokerage * 1.1);
        console.log("################################");
        console.log("What does the broker earn");
        const BrokerCommission = interestSum - questCommission;
        console.log("BrokerCommission", BrokerCommission);
        // Set the updated calculations
        setCalculations({
          purchasePrice: formatMoney(purchasePrice),
          cashDeposit: formatMoney(cashDeposit),
          tradeIn: formatMoney(tradeIn),
          payoutAmount: formatMoney(payoutAmount),
          netAmountFinanced: formatMoney(netAmountFinanced),
          originationFee: formatMoney(
            parseAmount(formData.brokerOriginationFee) * 1.1
          ),
          loanApplicationFee: formatMoney(
            parseAmount(formData.loanApplicationFee) * 1.1
          ),
          totalLoanAmount: formatMoney(totalLoanAmount),
          balloonPayment: formatMoney(balloonPayment),
          periodicPayment: formatMoney(paymentMonthly),
          brokerage: formatMoney(brokerage),
          paymentMonthly: formatMoney(paymentMonthly),
          paymentFrequency: paymentCycle,
          paymentTiming: paymentTime,
          BrokerCommission: BrokerCommission,
          questCommission: formatMoney(questCommission),
          formData: formData,
        });
      };

      calculateSummary();
    }
  }, [formData]);

  return (
    <div className="iphone">
      <div
        className="loan-summary-page"
        style={{
          backgroundColor: "#007BFF",
          padding: "20px",
          minHeight: "100vh",
        }}
      >
        <div
          className="header"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={backarrow}
            alt="Back"
            onClick={onBack}
            style={{ width: 26, height: 26, cursor: "pointer" }}
          />
          <img
            src={logo}
            alt="Logo"
            style={{ width: 100, height: 100, margin: "auto" }}
          />
        </div>

        <div
          className="loan-summary-container"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <h2
            style={{
              color: "#3D1C80",
              textAlign: "center",
              fontSize: "24px",
              marginBottom: "20px",
            }}
          >
            Loan Summary
          </h2>

          <div className="section" style={{ marginBottom: "20px" }}>
            <h3
              style={{ fontSize: "18px", fontWeight: "bold", color: "#3D1C80" }}
            >
              Loan Details
            </h3>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Purchase Price:</p>
              <p>{calculations.purchasePrice}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Deposit:</p>
              <p>{calculations.cashDeposit}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Trade In:</p>
              <p>{calculations.tradeIn}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Payout Amount:</p>
              <p>{calculations.payoutAmount}</p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>Net Amount Financed:</p>
              <p>{calculations.netAmountFinanced}</p>
            </div>
            <div className="summary-item total">
              <span>Fees </span>
            </div>
            <div className="summary-item">
              <span>Brokerage </span>
              <span style={{ color: "#3D1C80" }}>{calculations.brokerage}</span>
            </div>
            <div className="summary-item">
              <span>Balloon Payment </span>
              <span style={{ color: "#3D1C80" }}>
                {calculations.balloonPayment}
              </span>
            </div>
            <div className="summary-item">
              <span>Origination Fee (incl. GST) </span>
              <span style={{ color: "#3D1C80" }}>
                {calculations.originationFee}
              </span>
            </div>
            <div className="summary-item">
              <span>Application Fee (incl. GST) </span>
              <span style={{ color: "#3D1C80" }}>
                {calculations.loanApplicationFee}
              </span>
            </div>
            <div className="summary-item total">
              <span>Loan Details </span>
            </div>
            <div className="summary-item">
              <span>Total Loan Amount </span>
              <span style={{ color: "#3D1C80" }}>
                {calculations.totalLoanAmount}
              </span>
            </div>
            <div className="summary-item">
              <span>Repayment ({calculations.paymentFrequency}) </span>
              <span style={{ color: "#3D1C80" }}>
                {calculations.paymentMonthly}
              </span>
            </div>
          </div>

          {/* Other loan details go here */}
        </div>
        {/* InfoBox */}
        <InfoBox
          title="Important Information"
          content="This summary provides an overview of the loan details. Please review carefully before proceeding."
          backgroundColor="#f8f9fa"
          totalCommission={
            isNaN(calculations.BrokerCommission)
              ? "$0.00"
              : Number(calculations.BrokerCommission.toFixed(2))
          }
        />
        {/* this is a button to email the modal. */}
        <button
          className="email-quote-button"
          onClick={openModal}
          style={{
            backgroundColor: "#05dd80",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <img
            src={emailIcon}
            alt="Email Icon"
            style={{ width: 26, height: 26, marginRight: 10 }}
          />
          Email Quote
        </button>
        {/* Modal */}
        <EmailQuoteModal
          onClose={closeModal}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          emailAddress={emailAddress}
          setEmailAddress={setEmailAddress}
          formJson={formData}
          repayment={calculations.periodicPayment}
          netAmountFinanced={calculations.netAmountFinanced}
          totalLoanAmount={calculations.totalLoanAmount}
        />
      </div>
    </div>
  );
};

LoanSummary.propTypes = {
  onBack: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default LoanSummary;
