import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import RadioGroup from "../../../../../shared/components/RadioGroup";
import { reportError } from "../../../../../shared/error-monitoring";
import {
  handleEnterKey,
  preventChangeFocus,
} from "../../../../../shared/helpers";
import { OTP_ERROR_MESSAGES } from "../../../../constants/errors";
import { MOBILE_VALIDATION_PATTERN } from "../../../../constants/shared";
import {
  LEAD_SET_FIELDS,
  LEAD_SET_STEP_LOADING,
  LEAD_SET_LEAD_ID,
} from "../../../../reducers/actions";
import { resendOtp, verifyOtp } from "../../../../utils/api";

const OtpStep = ({ handleResults }) => {
  const mobile = useSelector((state) => state.leadForm.fields.mobile);
  const isLoading = useSelector((state) => state.leadForm.stepLoading);
  const leadGuid = useSelector((state) => state.leadForm.leadGuid);
  const amount = useSelector((state) => state.leadForm.fields.amount);
  const dispatch = useDispatch();

  const receivedOptions = [
    {
      value: "YES",
      label: "Yes",
    },
    {
      value: "NO",
      label: "No",
    },
  ];

  const [isReceived, setIsReceived] = useState("");
  const [isResent, setIsResent] = useState(false);
  const [otpCode, setOtpCode] = useState("");

  const { register, errors, trigger, setError, clearErrors } = useForm();

  const mobileFieldName = "mobile";
  const otpFieldName = "otpCode";

  const handleChange = (event) => {
    if (event.target.name === mobileFieldName) {
      dispatch({
        type: LEAD_SET_FIELDS,
        payload: {
          mobile: event.target.value,
        },
      });
    } else if (event.target.name === otpFieldName) {
      setOtpCode(event.target.value);
    } else {
      setIsReceived(event.target.value);
    }
  };

  const resendPin = async () => {
    const validation = await trigger(mobileFieldName);
    if (!validation) return;

    dispatch({
      type: LEAD_SET_STEP_LOADING,
      payload: true,
    });

    clearErrors(mobileFieldName);

    try {
      const resend = await resendOtp({
        mobile,
        leadGuid,
      });

      dispatch({
        type: LEAD_SET_LEAD_ID,
        payload: resend.leadGuid,
      });

      setIsReceived("");
      setIsResent(true);
    } catch (error) {
      setError(mobileFieldName, {
        type: "manual",
        message:
          "There's something wrong when submitting your request. Please try again.",
      });
      reportError(error);
    } finally {
      dispatch({
        type: LEAD_SET_STEP_LOADING,
        payload: false,
      });
    }
  };

  const submitCode = async () => {
    const validation = await trigger(otpFieldName);
    if (!validation) return;

    dispatch({
      type: LEAD_SET_STEP_LOADING,
      payload: true,
    });

    clearErrors(otpFieldName);

    try {
      const results = await verifyOtp({
        leadGuid,
        otpCode,
      });

      handleResults(results.products, parseFloat(amount));
    } catch (error) {
      let message =
        "There's something wrong when submitting your request. Please try again.";
      if (error.errorCode) {
        message = OTP_ERROR_MESSAGES[error.errorCode];
      }

      setError(otpFieldName, {
        type: "manual",
        message,
      });

      if (error instanceof Error) {
        reportError(error);
        return;
      }
    } finally {
      dispatch({
        type: LEAD_SET_STEP_LOADING,
        payload: false,
      });
    }
  };

  const handleSubmit = () => {
    if (isReceived === "YES") {
      submitCode();
    } else {
      resendPin();
    }
  };

  const handleKeyDown = (event) => {
    handleEnterKey(event, () => {
      handleSubmit();
    });
  };

  return (
    <section
      className={`sqz-section ${isLoading ? "sqz-hide" : ""}`}
      id="step-otp"
      data-testid="form-step-otp"
    >
      <img
        src="/assets/images/typing-bubble.svg"
        alt="typing-bubble"
        className="sqz-typing_bubble"
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10 text-center">
            <div className="sqz-question_header mb-4">
              <h2>Almost there!</h2>
              <p>
                We&apos;ve just {isResent ? "resent" : "sent"} a secure code to
                your mobile. <br /> Did you receive it?
              </p>
            </div>
            <div className="sqz-form_row">
              <RadioGroup
                name="received"
                options={receivedOptions}
                checked={isReceived}
                onChange={handleChange}
                center={true}
              />
            </div>
            {isReceived === "NO" && (
              <div className="sqz-form_row">
                <p className="big">
                  Uh-oh. Please enter your mobile number and we&apos;ll resend
                  it.
                </p>
                <div className="sqz-middle-form">
                  <input
                    type="tel"
                    className="form-control required no-max-width"
                    name="mobile"
                    value={mobile}
                    data-testid="mobileConfirmation"
                    onChange={handleChange}
                    id="sqz-field_10_4"
                    maxLength={10}
                    ref={register({
                      required: "Please fill out your mobile phone number",
                      minLength: {
                        value: 10,
                        message:
                          "Invalid mobile number. Please use the following format: 04xxxxxxxx (e.g. 0412345678)",
                      },
                      pattern: {
                        value: MOBILE_VALIDATION_PATTERN,
                        message:
                          "Invalid mobile number. Please use the following format: 04xxxxxxxx (e.g. 0412345678)",
                      },
                    })}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                {errors.mobile && (
                  <label className="error">{errors.mobile.message}</label>
                )}
              </div>
            )}
            {isReceived === "YES" && (
              <div className="sqz-form_row">
                <p className="big">Great! Please enter the secure code here</p>
                <div className="sqz-middle-form">
                  <input
                    type="tel"
                    className="form-control required no-max-width"
                    name="otpCode"
                    value={otpCode}
                    data-testid="otpCode"
                    onChange={handleChange}
                    id="sqz-field_10_4"
                    maxLength={10}
                    ref={register({
                      required:
                        "Please enter the secure code we've sent to your mobile",
                    })}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                {errors.otpCode && (
                  <label className="error">{errors.otpCode.message}</label>
                )}
              </div>
            )}
            {(isReceived === "YES" || isReceived === "NO") && (
              <div className="text-center">
                <button
                  type="button"
                  className="sqz-btn sqz-next mt-4"
                  data-testid="submitButton"
                  onKeyDown={preventChangeFocus}
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isReceived === "YES" ? "CONTINUE" : "RESEND CODE"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

OtpStep.propTypes = {
  handleResults: PropTypes.func,
};

export default OtpStep;
