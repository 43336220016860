import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { product } from "../../types";
import DisclaimerModal from "../DisclaimerModal";
import ProductCard from "../ProductCard";

const QuoteOutput = ({ loanAmount, products }) => {
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const variant = useSelector((state) => state.leadForm.fields.variant);
  const leadGuid = useSelector((state) => state.leadForm.leadGuid);
  const renderProducts = (products) => {
    const bankStatementLink = `${process.env.REACT_APP_BANK_STATEMENT_LINK}${leadGuid}`;
    return products.map((product, index) => {
      return (
        <ProductCard
          key={index}
          loanAmount={loanAmount}
          product={product}
          variant={variant}
          optionNumber={index + 1}
          bankStatementLink={bankStatementLink}
        />
      );
    });
  };

  return (
    <React.Fragment>
      <div className="sqz-content sqz-pb_0">
        <div
          className={`sqz-intro_content ${
            products.length === 0 ? "sqz-pb_0" : ""
          }`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-8 col-lg-6 text-center">
                {products.length > 0 ? (
                  <React.Fragment>
                    <h1>Well done!</h1>
                    <h4>Here is your quote.</h4>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h1>UH-OH.</h1>
                    <h4>
                      Something weird just happened on our end, sorry! <br />{" "}
                    </h4>
                    <p>Please refresh and try again :)</p>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        {products.length > 0 && (
          <div className="container">
            <div className="row justify-content-center">
              {renderProducts(products)}
            </div>
          </div>
        )}
        <div className="container">
          <div
            className={`sqz-additional_info text-center ${
              products.length === 0 ? "pt-0" : ""
            }`}
          >
            {products.length > 0 && (
              <React.Fragment>
                <h4>What&apos;s Next?</h4>
                <ol>
                  <li>1. Link your bank account</li>
                  <li>2. Apply and get approved</li>
                  <li>3. Sign documents online and get funded</li>
                </ol>
                <span className="d-block mb-16">OR</span>
              </React.Fragment>
            )}
            <p>
              <strong>Speak to a lending expert</strong>Call us{" "}
              <a href="tel:1300 465 363">1300 465 363</a>
            </p>
            <div className="bank-transactions-info">
              <p>
                Bank transactions history is a compulsory part of the
                application and will not affect your credit score.
              </p>
            </div>
            <div className="sqz-connect">
              <p>Connect with us</p>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/questequipmentloans"
                    id="facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 0C7.164 0 0 7.164 0 16C0 24.0213 5.90933 30.6453 13.608 31.8027V20.24H9.64933V16.0347H13.608V13.236C13.608 8.60267 15.8653 6.56933 19.716 6.56933C21.56 6.56933 22.536 6.70667 22.9973 6.768V10.4387H20.3707C18.736 10.4387 18.1653 11.9893 18.1653 13.736V16.0347H22.956L22.3067 20.24H18.1653V31.836C25.9747 30.7773 32 24.1 32 16C32 7.164 24.836 0 16 0Z"
                        fill="#EB3359"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/questbusinessloans"
                    id="instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.33073 0C4.1854 0 0 4.18927 0 9.33594V22.6693C0 27.8146 4.18927 32 9.33594 32H22.6693C27.8146 32 32 27.8107 32 22.6641V9.33073C32 4.1854 27.8107 0 22.6641 0H9.33073ZM25.3333 5.33333C26.0693 5.33333 26.6667 5.93067 26.6667 6.66667C26.6667 7.40267 26.0693 8 25.3333 8C24.5973 8 24 7.40267 24 6.66667C24 5.93067 24.5973 5.33333 25.3333 5.33333ZM16 8C20.412 8 24 11.588 24 16C24 20.412 20.412 24 16 24C11.588 24 8 20.412 8 16C8 11.588 11.588 8 16 8ZM16 10.6667C14.5855 10.6667 13.229 11.2286 12.2288 12.2288C11.2286 13.229 10.6667 14.5855 10.6667 16C10.6667 17.4145 11.2286 18.771 12.2288 19.7712C13.229 20.7714 14.5855 21.3333 16 21.3333C17.4145 21.3333 18.771 20.7714 19.7712 19.7712C20.7714 18.771 21.3333 17.4145 21.3333 16C21.3333 14.5855 20.7714 13.229 19.7712 12.2288C18.771 11.2286 17.4145 10.6667 16 10.6667Z"
                        fill="#EB3359"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/company/questbusinessloans"
                    id="linkedin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.9999 0.333252H2.99992C1.52659 0.333252 0.333252 1.52659 0.333252 2.99992V26.9999C0.333252 28.4733 1.52659 29.6666 2.99992 29.6666H26.9999C28.4733 29.6666 29.6666 28.4733 29.6666 26.9999V2.99992C29.6666 1.52659 28.4733 0.333252 26.9999 0.333252ZM9.60525 24.3333H5.67192V11.6773H9.60525V24.3333ZM7.59859 9.86792C6.33059 9.86792 5.30525 8.83992 5.30525 7.57458C5.30525 6.30925 6.33192 5.28259 7.59859 5.28259C8.86259 5.28259 9.89059 6.31059 9.89059 7.57458C9.89059 8.83992 8.86259 9.86792 7.59859 9.86792ZM24.3386 24.3333H20.4079V18.1786C20.4079 16.7106 20.3813 14.8226 18.3639 14.8226C16.3173 14.8226 16.0026 16.4213 16.0026 18.0719V24.3333H12.0719V11.6773H15.8453V13.4066H15.8986C16.4239 12.4119 17.7066 11.3626 19.6199 11.3626C23.6026 11.3626 24.3386 13.9839 24.3386 17.3919V24.3333Z"
                        fill="#EB3359"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <footer className="sqz-footer text-center">
        <div className="container">
          {products.length > 0 && (
            <p>
              <span
                className="cursor-pointer text-underline"
                onClick={() => {
                  setShowDisclaimerModal(true);
                }}
                data-testid="showDisclaimerModalButton"
              >
                Disclaimer
              </span>
            </p>
          )}
          <p>
            <a href="https://quest.finance/help-support/privacy-policy/">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="https://quest.finance/help-support/terms-conditions/">
              Terms & Conditions
            </a>
          </p>
        </div>
      </footer>
      <DisclaimerModal
        isShown={showDisclaimerModal}
        toggleModal={setShowDisclaimerModal}
      />
    </React.Fragment>
  );
};

QuoteOutput.propTypes = {
  loanAmount: PropTypes.number,
  products: PropTypes.arrayOf(product),
};

export default QuoteOutput;
