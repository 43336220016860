import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const DisclaimerModal = ({ isShown, toggleModal }) => {
  return (
    <Modal
      show={isShown}
      data-testid="modal-disclaimer"
      className="modal-disclaimer"
    >
      <Modal.Body className="text-left">
        <p>
          * Quote is determined with reference to full credit assessment. This
          is not an offer. Lending criteria, credit assessments, and conditions
          apply. Rates, fees and conditions can change at any time without
          notice. Loan approvals are subject to the provision of all required
          information and supporting documents on application. Please note that
          the quote we display is indicative and subject to change based on
          further questions. Any advice given above is general and does not take
          into account all your objectives, financial situation or needs.
        </p>
        <button
          onClick={() => toggleModal(false)}
          className="close-button"
          data-testid="dismiss-modal-disclaimer"
        >
          Close
        </button>
      </Modal.Body>
    </Modal>
  );
};

DisclaimerModal.propTypes = {
  isShown: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default DisclaimerModal;
